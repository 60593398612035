import styled from "styled-components";

export const Photo = styled.img`
  height: 130px;
  width: 130px;
  margin: 40px 0 15px 0;
`;

export const Message = styled.p`
  font-size: 18px;
  width: 80%;
  max-width: 250px;
  margin: 25px 0;
  color: #fff;
  font-family: "Montserrat-Medium";
  text-align: center;
`;

export const RowRedes = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100vw;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Download = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const Loja = styled.img`
  margin: 0px 25px;
  cursor: pointer;
`;
