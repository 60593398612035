import ReactInputMask from "react-input-mask";
import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  justify-content: space-between;
  background-color: #363636;

  input,
  textarea {
    font-size: 16px;
  }
`;

export const ViewMax = styled.div`
  width: 100%;
  max-width: 500px;
  position: relative;
  display: flex;
  flex: 1;
  padding: 50px 0;
  flex-direction: column;
  align-items: center;
`;

export const Circle = styled.div`
  height: 55px;
  width: 55px;
  border-radius: 55px;
  background-color: #ffc700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
  font-family: "Montserrat-Medium";
`;

export const ViewInput = styled.div`
  min-height: 40px;
  border-radius: 5px;
  width: 90%;
  margin-bottom: 5px;
  justify-content: space-between;
  text-align: center;
  font-family: "Montserrat-Regular";

  p {
    width: 100%;
    font-size: 12px;
    text-align: left;
    margin: 0 0 5px 5px;
    color: #fff;
    font-family: "Montserrat-Medium";
  }

  .ant-input-group-addon {
    background-color: #fff;
  }
`;

export const Title = styled.p`
  width: 90%;
  font-size: 18px;
  text-align: center;
  margin-top: 70px;
  color: #fff;
  font-family: "Montserrat-Medium";
`;

export const SubTitle = styled.p`
  width: 80%;
  font-size: 14px;
  text-align: center;
  margin: 15px 0 50px 0;
  color: #fff;
  font-family: "Montserrat-Regular";
`;

export const ButtonBack = styled.button`
  height: 35px;
  border-radius: 35px;
  width: 35px;
  display: flex;
  padding: 0 2px 0 0;
  position: absolute;
  background-color: #fff;
  top: 25px;
  left: 5%;
  justify-content: center;
  align-items: center;
`;

export const InputTel = styled(ReactInputMask)`
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: "Montserrat-Regular";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 2px;
  transition: all 0.2s;
  ::placeholder {
    color: #bbb;
    font-size: 13px;
  }
  &:hover {
    border-color: #20d45f;
  }
  input:focus {
    border-color: #20d45f;
  }
`;
