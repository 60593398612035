import React, { useEffect, useState } from "react";
import { Banner, Container, Footer, Partner } from "./styles";
import { useParams } from "react-router-dom";
import loadingPhoto from "./../images/loading.gif";
import prime from "./../images/prime.png";
import api from "../services/api";
import Profile from "./../Profile";
import Error from "./../Error";
import { Fundo } from "../Profile/styles";
import logo from "./../images/logo.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function Home() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [profile, setProfile] = useState({
    name: "",
    photo: "",
    photo_url: "",
    description: "",
    links: [],
    sociais: [],
    partners: [],
  });

  useEffect(() => {
    loadingProfile();
  }, []);

  async function loadingProfile() {
    await api
      .get(`/profile/${id}`)
      .then((response) => {
        let profileC = response.data;
        if (profileC.redirect) {
          window.location.href = profileC.redirect;
        } else {
          let partners = [];
          if (
            (profileC.plan_name == "free" ||
              profileC.plan_name == "promocional" ||
              profileC.view_partner) &&
            profileC.partner
          ) {
            partners.push(profileC.partner);
          }
          profileC.partners.map((item) => {
            partners.push(item);
          });
          if (profileC.plan_name.includes("prime")) {
            partners.push({
              name: "Aqui seu dinheiro vale mais!",
              photo_url: prime,
              url: "https://qrcartaoweb.com.br/user/clubedeofertasprime/qrads/fe778f5d-a2eb-4131-b6a3-4faedf55bebf",
            });
          }
          profileC.partners = [...partners];
          setProfile(profileC);
          setLoading(false);
        }
      })
      .catch(({ response }) => {
        if (response.data) {
          if (
            response.data.message === "Nenhum perfil ou tag foi identificado"
          ) {
            setError("Perfil");
          }
          if (
            response.data.message === "Nenhum perfil foi vinculado a essa tag"
          ) {
            setError("Tag");
          }
          if (
            response.data.message ===
            "Esse perfil atingiu o limite de leituras mensal"
          ) {
            setError("Limite");
          }
        } else {
          setError("Interno");
        }
        setLoading(false);
      });
  }

  return (
    <Container
      style={{
        backgroundColor: profile.background ? profile.background : "#363636",
      }}
    >
      {!!profile.background_image && profile.background_visible && (
        <Fundo
          src={profile.background_image_url}
          style={{ filter: `blur(${profile.background_blur}px)` }}
        />
      )}
      {loading ? (
        <img
          src={loadingPhoto}
          style={{
            background: "transparent",
            height: 80,
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            margin: "auto",
          }}
        />
      ) : (
        <>
          {error ? <Error error={error} /> : <Profile profileParam={profile} />}
          <Carousel
            autoPlay
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            style={{ width: 250, maxWidth: "100px !important" }}
            infiniteLoop
          >
            {profile.partners.map((item) => {
              return (
                <Partner
                  style={{
                    cursor: item.partner?.url || item.url ? "pointer" : "none",
                  }}
                  onClick={() => {
                    window.location.assign(item.partner?.url || item.url);
                  }}
                >
                  <span
                    style={{
                      color: profile.font_color ? profile.font_color : "#fff",
                    }}
                  >
                    {item.partner?.name || item.name}
                  </span>
                  <img src={item.partner?.photo_url || item.photo_url} />
                </Partner>
              );
            })}
          </Carousel>
          {!error && (
            <Footer
              onClick={() => {
                window.location.assign("https://qrcartaoweb.com.br/user/qrbio");
              }}
            >
              {!profile.plan_name || profile.plan_name === "free" ? (
                <>
                  <p>Crie um site como esse gratuitamente em </p>
                  <img src={logo} alt="QR Bio" />
                </>
              ) : (
                <>
                  <img src={logo} alt="QR Bio" />
                </>
              )}
            </Footer>
          )}
        </>
      )}
    </Container>
  );
}
