import React, { useState } from "react";
import {
  ButtonBack,
  Circle,
  Container,
  SubTitle,
  Title,
  ViewInput,
  ViewMax,
} from "./styles";
import playstore from "./../images/playstore.png";
import appstore from "./../images/appstore.png";
import arrow from "./../images/arrow.png";
import logo from "./../images/logo.png";
import star from "./../images/star.png";
import { Button, Input } from "antd";
import { MdChevronLeft } from "react-icons/md";
import ReactInputMask from "react-input-mask";
import { useQuery } from "../hooks/Location";
import api from "../services/api";
import { toast } from "react-toastify";
import { Download, Loja, Message, Photo } from "../Error/styles";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const navigate = useNavigate();
  const query = useQuery();

  const qrcode = query.get("qrcode");

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorNickname, setErrorNickname] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(true);
  const [nickname, setNickname] = useState("");
  const [description, setDescription] = useState("");
  const [instagram, setInstagram] = useState("");
  const [code, setCode] = useState("");
  const [etapa, setEtapa] = useState(qrcode ? 1 : 0);

  async function createUser() {
    setLoading(true);
    await api
      .post("/user-web", {
        name: name,
        email: email.toLowerCase(),
        phone_number: phone_number,
        password: password,
        nickname: nickname.replace(/ /g, "").toLowerCase(),
        description: description,
        code: code,
        qrcode: qrcode || "",
        instagram: instagram,
      })
      .then(() => {
        toast.success("Cadastro realizado com sucesso");
        setConfirm(true);
      })
      .catch(({ response }) => {
        if (response?.data?.message) {
          if (response?.data?.message == "Usuário já está em uso") {
            setErrorNickname(true);
            setEtapa(5);
          }
          if (
            response?.data?.message == "Email já cadastrado" ||
            response?.data?.message == "Email é inválido"
          ) {
            setErrorEmail(true);
            setEtapa(3);
          }
          toast.error(response?.data?.message);
        } else {
          toast.error("Ocorreu um erro ao realizar o cadastro");
        }
      });
    setLoading(false);
  }

  return (
    <Container>
      {confirm ? (
        <ViewMax style={{ justifyContent: "center" }}>
          <Message
            style={{
              marginTop: 0,
            }}
          >
            Cadastro concluído com sucesso!
          </Message>
          <Circle>1</Circle>
          <Message
            style={{
              fontSize: 12,
              marginTop: 0,
              marginBottom: 5,
              maxWidth: 300,
            }}
          >
            Acesse sua caixa de email para verificar a conta e baixar o
            aplicativo
          </Message>

          <Button
            type="primary"
            style={{
              margin: "20px 0",
              width: "80%",
              height: 40,
              fontSize: 18,
              maxWidth: 300,
              borderRadius: 10,
              color: "#363636",
              fontFamily: "Montserrat-Medium",
            }}
            onClick={() => {
              window.location.assign("message://");
            }}
          >
            Confirmar email
          </Button>
          <img src={arrow} />
          <Circle style={{ marginTop: 15 }}>2</Circle>
          <Message style={{ fontSize: 12, marginTop: 0, maxWidth: 300 }}>
            Baixe o aplicativo e tenha o melhor da tecnologia na palma da sua
            mão.
          </Message>
          <Download style={{ marginTop: 0, marginBottom: 20 }}>
            <Loja
              onClick={() => {
                window.location.assign(
                  "https://apps.apple.com/br/app/qr-bio/id6444109866"
                );
              }}
              src={appstore}
            />
            <Loja
              onClick={() => {
                window.location.assign(
                  "https://play.google.com/store/apps/details?id=solucoesdaweb.qrbio"
                );
              }}
              src={playstore}
            />
          </Download>

          <img src={arrow} />
          <Circle style={{ marginTop: 15, marginBottom: 5 }}>3</Circle>
          <Button
            type="primary"
            style={{
              margin: "20px 0",
              width: "80%",
              height: 40,
              fontSize: 18,
              maxWidth: 300,
              borderRadius: 10,
              color: "#363636",
              fontFamily: "Montserrat-Medium",
            }}
            onClick={() => {
              navigate(`/${nickname}`);
            }}
          >
            Visualizar Perfil
          </Button>
          <img src={logo} style={{ height: 20 }} />
          <Message
            style={{
              fontSize: 12,
              marginTop: 5,
              marginBottom: 5,
              maxWidth: 300,
            }}
          >
            qrbio.com.br
          </Message>
        </ViewMax>
      ) : (
        <ViewMax>
          {etapa > 1 && (
            <ButtonBack
              disabled={loading}
              onClick={() => {
                setEtapa(etapa - 1);
              }}
            >
              <MdChevronLeft color="#363636" size={30} />
            </ButtonBack>
          )}
          {etapa == 0 && (
            <>
              <>
                <Photo src={star} style={{ marginTop: 80 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Message>Seja bem-vindo ao </Message>
                  <img src={logo} style={{ height: 25, marginLeft: 5 }} />
                </div>

                <Message style={{ fontSize: 12, marginTop: 0, maxWidth: 300 }}>
                  Estamos felizes em ter você conosco!
                </Message>
                <Message style={{ fontSize: 12, marginTop: 0, maxWidth: 300 }}>
                  Vamos iniciar seu cadastro, preencha todas informações para
                  seu perfil ficar completo.
                </Message>
                <Button
                  type="primary"
                  style={{
                    margin: "10px 0",
                    width: "70%",
                    height: 40,
                    fontSize: 16,
                    maxWidth: 240,
                    borderRadius: 8,
                    color: "#363636",
                    fontFamily: "Montserrat-Medium",
                  }}
                  onClick={() => {
                    setEtapa(1);
                  }}
                >
                  Criar nova conta
                </Button>
              </>
            </>
          )}
          {etapa == 1 && (
            <>
              <Title>Qual o seu nome?</Title>
              <SubTitle></SubTitle>
              <ViewInput>
                <p>NOME</p>
                <Input
                  value={name}
                  placeholder="digite seu nome"
                  style={{
                    height: 40,
                    fontFamily: "Montserrat-Medium",
                  }}
                  onChange={(e) => setName(e.target.value)}
                />
              </ViewInput>
            </>
          )}
          {etapa == 2 && (
            <>
              <Title>Informe o número do seu celular?</Title>
              <SubTitle>
                Confirme que o código de área está incluido no seu numero de
                celular, esse número será no seu perfil
              </SubTitle>
              <ViewInput>
                <p>NÚMERO DE CELULAR</p>
                <ReactInputMask
                  mask="99 99999-9999"
                  value={phone_number}
                  onChange={(e) => setPhone_number(e.target.value.slice(0, 13))}
                  maskChar={null}
                >
                  {(inputProps) => (
                    <Input
                      placeholder="digite seu telefone"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      style={{
                        height: 40,
                        fontFamily: "Montserrat-Medium",
                      }}
                    />
                  )}
                </ReactInputMask>
              </ViewInput>
            </>
          )}
          {etapa == 3 && (
            <>
              <Title>Adicione o seu e-mail</Title>
              <SubTitle>
                Você usará esse email quando fizer login e sempre que precisar
                redefinir sua senha.
              </SubTitle>
              <ViewInput>
                <p>EMAIL</p>
                <Input
                  value={email}
                  status={errorEmail && "error"}
                  placeholder="digite seu email"
                  inputMode="email"
                  autoCapitalize="off"
                  style={{
                    height: 40,
                    fontFamily: "Montserrat-Medium",
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value.replace(/ /g, "").toLowerCase());
                    setErrorEmail(false);
                  }}
                />
              </ViewInput>
            </>
          )}
          {etapa == 4 && (
            <>
              <Title>Escolha sua senha</Title>
              <SubTitle>
                Crie uma senha com pelo menos 6 carecteres. Ela deve ser algo
                que outras pessoas não consigam adivinhar.
              </SubTitle>
              <ViewInput>
                <p>SENHA</p>
                <Input.Password
                  value={password}
                  placeholder="digite sua senha"
                  style={{
                    height: 40,
                    fontFamily: "Montserrat-Medium",
                  }}
                  autoCapitalize="off"
                  visibilityToggle={{
                    visible: passwordVisible,
                    onVisibleChange: setPasswordVisible,
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </ViewInput>
              <ViewInput style={{ marginTop: 10 }}>
                <p>CONFIRMAR SENHA</p>
                <Input.Password
                  value={passwordConfirm}
                  placeholder="confirme sua senha"
                  autoCapitalize="off"
                  style={{
                    height: 40,
                    fontFamily: "Montserrat-Medium",
                  }}
                  visibilityToggle={{
                    visible: passwordConfirmVisible,
                    onVisibleChange: setPasswordConfirmVisible,
                  }}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
              </ViewInput>
            </>
          )}

          {etapa == 5 && (
            <>
              <Title>Usuário de Perfil</Title>
              <SubTitle>
                É usado para identificação do perfil nos QR Codes.
              </SubTitle>
              <ViewInput>
                <p>USUÁRIO</p>
                <Input
                  value={nickname}
                  addonBefore="qrbio.me/"
                  placeholder="digite seu usuário"
                  autoCapitalize="off"
                  status={errorNickname && "error"}
                  styles={{
                    addonBefore: {
                      backgroundColor: "#fffFFF",
                      padding: "5px 10px",
                      borderRadius: "6px 0 0 6px",
                    },
                  }}
                  style={{
                    height: 40,
                    fontFamily: "Montserrat-Medium",
                  }}
                  onChange={(e) => {
                    setNickname(e.target.value.replace(/ /g, "").toLowerCase());
                    setErrorNickname(false);
                  }}
                />
              </ViewInput>
            </>
          )}
          {etapa == 6 && (
            <>
              <Title>Biografia do Perfil</Title>
              <SubTitle>
                Faça uma descrição sobre voce ou use pra colocar um cargo,
                profissão ou slogan
              </SubTitle>
              <ViewInput>
                <p>BIOGRAFIA DO PERFIL</p>
                <Input.TextArea
                  value={description}
                  placeholder="biografia do Perfil "
                  style={{
                    minHeight: 150,
                    fontFamily: "Montserrat-Medium",
                  }}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </ViewInput>
            </>
          )}

          {etapa == 7 && (
            <>
              <Title>Adicione o instagram</Title>
              <SubTitle></SubTitle>
              <ViewInput>
                <p>INSTAGRAM</p>
                <Input
                  value={instagram}
                  addonBefore="@"
                  placeholder="digite seu usuario do instagram"
                  autoCapitalize="off"
                  styles={{
                    addonBefore: {
                      backgroundColor: "#fffFFF",
                      padding: "5px 10px",
                      borderRadius: "6px 0 0 6px",
                    },
                  }}
                  style={{
                    height: 40,
                    fontFamily: "Montserrat-Medium",
                  }}
                  onChange={(e) =>
                    setInstagram(e.target.value.replace(/ /g, "").toLowerCase())
                  }
                />
              </ViewInput>
            </>
          )}

          {etapa == 8 && (
            <>
              <Title>Resgatar cupom</Title>
              <SubTitle>
                Insira o seu cupom para resgatar, caso não tenha. Pode avançar.
              </SubTitle>
              <ViewInput>
                <p>CÓDIGO DE RESGATE</p>
                <Input
                  value={code}
                  placeholder="digite o código"
                  style={{
                    height: 40,
                    fontFamily: "Montserrat-Medium",
                  }}
                  allowClear
                  onChange={(e) =>
                    setCode(e.target.value.replace(/ /g, "").toUpperCase())
                  }
                />
              </ViewInput>
            </>
          )}
          {etapa > 0 && (
            <Button
              loading={loading}
              type="primary"
              style={{
                marginTop: 30,
                width: "90%",
                fontSize: 16,
                height: 40,
                color: "#363636",
                fontFamily: "Montserrat-Medium",
              }}
              onClick={() => {
                switch (etapa) {
                  case 1: {
                    if (!name) {
                      toast.warn("Preencha o nome do perfil");
                    } else {
                      setEtapa(etapa + 1);
                    }
                    break;
                  }
                  case 2: {
                    if (!phone_number) {
                      toast.warn("Preencha o telefone do perfil");
                    } else {
                      if (phone_number.length != 13) {
                        toast.warn("Preencha um telefone válido");
                      } else {
                        setEtapa(etapa + 1);
                      }
                    }
                    break;
                  }
                  case 3: {
                    if (!email) {
                      toast.warn("Preencha o email do perfil");
                    } else {
                      setEtapa(etapa + 1);
                    }
                    break;
                  }
                  case 4: {
                    if (!password || !passwordConfirm) {
                      toast.warn("Preencha a senha e confirmação de senha");
                    } else {
                      if (password != passwordConfirm) {
                        toast.warn("Senhas não correspondem");
                      } else {
                        setEtapa(etapa + 1);
                      }
                    }
                    break;
                  }
                  case 5: {
                    if (!nickname) {
                      toast.warn("Preencha o usuário do perfil");
                    } else {
                      setEtapa(etapa + 1);
                    }
                    break;
                  }
                  case 6: {
                    setEtapa(etapa + 1);
                    break;
                  }
                  case 7: {
                    setEtapa(etapa + 1);
                    break;
                  }
                  case 8: {
                    createUser();
                  }
                }
              }}
            >
              {etapa == 8 ? "Concluir Cadastro" : "Avançar"}
            </Button>
          )}
        </ViewMax>
      )}
    </Container>
  );
}
