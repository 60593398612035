import React from "react";
import { Routes, Route } from "react-router-dom";

import Redirect from "../Redirect";
import Home from "../Home";
import Register from "../Register";

const Routers = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Redirect />} />
      <Route exact path="*" element={<Redirect />} />
      <Route exact path="/:id" element={<Home />} />
      <Route exact path="/user/cadastro" element={<Register />} />
    </Routes>
  );
};

export default Routers;
