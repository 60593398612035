import React from "react";
import Routers from "./routers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#20d45f",
          colorPrimaryHover: "#333",
          borderRadius: 4,
          colorFill: "#001B22",
          colorIcon: "#001B22",
          colorBgContainer: "#fff",
        },
        components: {
          Button: {
            colorPrimary: "#20d45f",
            colorPrimaryHover: "#20d45fCC",
            boxShadow: 0,
          },
          Input: {
            lineWidthFocus: 1,
          },
        },
      }}
    >
      <Routers />
      <ToastContainer autoClose={3000} theme="colored" />
    </ConfigProvider>
  );
}

export default App;
