import React from "react";
import { Photo, Message, Download, Loja, Container } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import attention from "./../images/attention.png";
import star from "./../images/star.png";
import playstore from "./../images/playstore.png";
import appstore from "./../images/appstore.png";
import logo from "./../images/logo.png";
import { Button } from "antd";

export default function Error({ error }) {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Container style={error == "Tag" ? { justifyContent: "flex-start" } : {}}>
      {error == "Tag" ? (
        <Photo src={star} style={{ marginTop: 80 }} />
      ) : (
        <Photo src={attention} />
      )}
      {error == "Tag" ? (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Message>Seja bem-vindo ao </Message>
            <img src={logo} style={{ height: 25, marginLeft: 5 }} />
          </div>

          <Message style={{ fontSize: 12, marginTop: 0, maxWidth: 300 }}>
            Estamos felizes em ter você conosco!
          </Message>
          <Message style={{ fontSize: 12, marginTop: 0, maxWidth: 300 }}>
            Vamos iniciar seu cadastro, preencha todas informações para seu
            perfil ficar completo.
          </Message>
          <Button
            type="primary"
            style={{
              margin: "10px 0",
              width: "70%",
              height: 35,
              fontSize: 16,
              maxWidth: 240,
              borderRadius: 8,
              color: "#363636",
              fontFamily: "Montserrat-Medium",
            }}
            onClick={() => {
              navigate(`/user/cadastro?qrcode=${id}`);
            }}
          >
            Criar nova conta
          </Button>
        </>
      ) : error == "Perfil" ? (
        <Message>O perfil que você está tentando acessar é inválido.</Message>
      ) : error == "Limite" ? (
        <Message>Esse perfil atingiu o limite de leituras mensal</Message>
      ) : (
        <Message>
          Erro Interno, verifique sua conexão ou entre em contato conosco
        </Message>
      )}
    </Container>
  );
}
